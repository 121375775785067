import request from '../Axios'

// 用户列表
export const labelList = data => {
  return request({
    url: '/label',
    method: 'get',
    params: data
  })
}

// 新增
export const addLabel = data => {
  return request({
    url: '/label',
    method: 'post',
    data
  })
}

// 修改
export const editLabel = data => {
  return request({
    url: '/label',
    method: 'put',
    data
  })
}

// 删除
export const deleteLabel = params => {
  return request({
    url: `/label?labelId=${params.id}`,
    method: 'delete'
  })
}

// 生成小程序码
export const qrcodeApp = data => {
  return request({
    url: `/qrcode/mini-app`,
    method: 'post',
    data,
    responseType: 'blob'
  })
}
