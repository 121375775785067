<template>
  <div>
    <el-form
      class="formStyle"
      ref="form"
      inline
      :model="formData"
      :label-width="labelWidth"
    >
      <el-form-item
        v-for="(item, index) in formLabels"
        :key="index"
        :style="`margin-right: 0;padding: 0 10px;width: ${
          item.width || `calc(100% / ${column});`
        }`"
        :label="item.title"
      >
        <!-- :style="`margin-right: 0;padding: 0 10px;width: calc(100% / ${column});`" -->
        <!-- :label="item.title" -->
        <!-- 下拉框 -->
        <el-select
          v-if="item.type == 'options'"
          v-model="formData[item.label]"
          :placeholder="item.placeholder"
          @change="handleRoleList($event, item.change)"
        >
          <el-option
            v-for="item in item.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-else-if="item.type == 'makeup'"
          v-model="formData[item.inputLabel]"
          placeholder="请输入"
          class="input-with-select"
        >
          <template #prepend>
            <el-select
              v-model="formData[item.selectLabel]"
              :placeholder="item.placeholder"
            >
              <el-option
                v-for="item in item.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </el-input>
        <!-- 时间选择器 -->
        <el-date-picker
          v-else-if="item.type == 'date'"
          v-model="formData[item.label]"
          type="date"
          :placeholder="item.placeholder"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
        />
        <el-date-picker
          v-else-if="item.type == 'datePicker'"
          v-model="formData[item.label]"
          type="daterange"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          range-separator="至"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          clearable
        />
        <el-date-picker
          v-else-if="item.type == 'datetimerange'"
          v-model="formData[item.label]"
          type="datetimerange"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          range-separator="至"
          format="YYYY-MM-DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          clearable
        />
        <!-- 输入框 -->
        <el-input
          v-else
          v-model="formData[item.label]"
          type="text"
          clearable
          @clear="clearChangeForm"
          :placeholder="item.placeholder"
        ></el-input>
      </el-form-item>
      <el-form-item :style="`margin-right: 0;padding: 0 10px;width: auto; `">
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
defineProps({
  formLabels: {
    // 表格格式列表
    type: Array,
    default: () => []
  },
  formData: {
    // 表格数据
    type: Object,
    default: () => {}
  },
  labelWidth: {
    // 标题的宽度
    type: String,
    default: '100px'
  },
  column: {
    // 一行多少
    type: Number,
    default: 4
  }
})
const emit = defineEmits(['clearForm', 'handleRoleLists'])
const clearChangeForm = () => {
  emit('clearForm')
}
const handleRoleList = (val, change) => {
  emit('handleRoleLists', val, change)
}
</script>

<style lang="scss" scoped>
div {
  :deep(.el-select) {
    width: 100%;
  }
}
div {
  :deep(.el-date-editor) {
    width: 100%;
  }
  :deep(.el-range-editor.el-input__inner) {
    padding: 0 10px;
  }
  :deep(.el-input-group__prepend) {
    background: transparent;
    padding: 0;
    width: 210px;
  }
}
</style>
<style lang="scss">
.formStyle {
  .el-form-item {
    &:nth-child(1n) {
      padding-left: 0 !important;
    }
  }
}
</style>
