<template>
  <div class="user">
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
      <el-button type="success" @click="addItem">新增</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #labelType>
        <el-table-column label="标签类型" align="center">
          <template #default="scoped"> 手动标签 </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" width="350" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="copyItem(scoped.row)"
            >
              复制地址
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="editItem(scoped.row)"
            >
              修改
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="lookCode(scoped.row)"
              v-if="scoped.row.wechatQrcode"
            >
              查看小程序码
            </el-button>
            <el-button
              v-else
              type="primary"
              size="small"
              @click="createdCode(scoped.row)"
            >
              生成小程序码
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="deleteItem(scoped.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog v-model="imgDialogVisible" title="小程序码" width="30%">
      <img :src="wecharQrcodeUrl" class="preImg" />
      <el-button
        @click="downloadImage"
        type="primary"
        style="margin: 20px auto; display: block"
        >下载</el-button
      >
      <a ref="downloadLink" style="display: none"></a>
    </el-dialog>
    <el-dialog
      v-model="labelVisible"
      :title="title"
      width="50%"
      class="user_dialog"
      :before-close="labelClose"
    >
      <div class="writeoff_box">
        <span><i>*</i>标签类型：</span>
        <el-radio-group v-model="from.labelType">
          <el-radio :label="0">手动标签</el-radio>
        </el-radio-group>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>标签名称：</span>
        <el-input
          v-model="from.labelName"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>渠道名称：</span>
        <el-input
          v-model="from.channelName"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>跳转页面：</span>
        <el-input
          v-model="from.jumpUrl"
          @input="jumpUrlInput"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span>备注：</span>
        <el-input
          v-model="from.remark"
          class="dialog_input"
          placeholder="请输入"
          type="textarea"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="remarkSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs, ref } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import { putObject } from '@/api/eventManagement'
const OSS = require('ali-oss')
let client = null
const downloadLink = ref(null)
import {
  labelList,
  addLabel,
  editLabel,
  deleteLabel,
  qrcodeApp
} from '@/api/userManagement/userLabel'
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '关键字',
      placeholder: '请输入标签名称'
    }
  ],
  fromData: {
    // 表单数据
    keywords: ''
  },
  formColumn: 4,
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'labelName', label: '标签名称' },
    { slot: 'labelType' },
    { prop: 'channelName', label: '渠道名称' },
    { prop: 'jumpUrl', label: '二维码地址' },
    { prop: 'remark', label: '备注' },
    { slot: 'action' }
  ],
  id: '',
  labelVisible: false,
  title: '',
  from: {
    labelId: '',
    labelType: 0,
    labelName: '',
    channelName: '',
    jumpUrl: '',
    remark: ''
  },
  wecharQrcode: '',
  wecharQrcodeUrl: '',
  imgDialogVisible: false
})

onMounted(() => {
  getDataList()
})
// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.labelName = fromData.value.keywords
  }
  labelList(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 路径
const jumpUrlInput = () => {
  if (from.value.jumpUrl.indexOf('.html') != -1) {
    from.value.jumpUrl = from.value.jumpUrl.replace('.html', '')
  }
}

// 新增
const addItem = () => {
  from.value = {
    labelId: '',
    labelType: 0,
    labelName: '',
    channelName: '',
    jumpUrl: '',
    remark: ''
  }
  title.value = '新增用户标签'
  labelVisible.value = true
}
const { toClipboard } = useClipboard()
// 复制链接
const copyItem = async row => {
  try {
    //复制
    await toClipboard(row.jumpUrl)
    ElMessage.success('复制成功')
  } catch (e) {
    //复制失败
    console.error(e)
  }
}

// 编辑
const editItem = row => {
  from.value = {
    labelId: row.id,
    labelType: 0,
    labelName: row.labelName,
    channelName: row.channelName,
    channelId: row.channelId,
    jumpUrl: row.jumpUrl,
    remark: row.remark
  }
  title.value = '编辑用户标签'
  labelVisible.value = true
}

// 删除
const deleteItem = row => {
  ElMessageBox.confirm('是否确认删除?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      deleteLabel({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 弹窗关闭
const labelClose = () => {
  from.value = {
    labelId: '',
    labelType: 0,
    labelName: '',
    channelName: '',
    channelId: '',
    jumpUrl: '',
    remark: ''
  }
  labelVisible.value = false
}

// 提交
const remarkSubmit = () => {
  if (from.value.labelName == '') {
    ElMessage.warning('请输入标签名称')
    return false
  }
  if (from.value.channelName == '') {
    ElMessage.warning('请输入渠道名称')
    return false
  }
  if (from.value.jumpUrl == '') {
    ElMessage.warning('请输入跳转页面')
    return false
  }
  if (from.value.jumpUrl.indexOf('pages') != 0) {
    ElMessage.warning('请以pages为开头')
    return false
  }
  const data = {
    labelType: from.value.labelType,
    labelName: from.value.labelName,
    channelName: from.value.channelName,
    jumpUrl: from.value.jumpUrl,
    remark: from.value.remark
  }
  if (from.value.labelId == '') {
    addLabel(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        labelClose()
        getDataList()
        labelVisible.value = false
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    data.labelId = from.value.labelId
    data.channelId = from.value.channelId
    if (wecharQrcode.value != '') {
      data.wechatQrcode = wecharQrcode.value
    }
    editLabel(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        labelClose()
        getDataList()
        labelVisible.value = false
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}

// 查看小程序码
const lookCode = row => {
  wecharQrcodeUrl.value = row.wechatQrcode
  imgDialogVisible.value = true
}

// 下载
const downloadImage = () => {
  if (downloadLink.value) {
    downloadLink.value.href = wecharQrcodeUrl.value
    downloadLink.value.download = 'image.png' // 下载的文件名

    // 触发链接的点击事件，触发下载
    downloadLink.value.click()
  }
}

// 生成小程序码
const createdCode = row => {
  from.value = row
  from.value.labelId = row.id
  const data = {
    scene: `labelId=${row.id}&channelId=${row.channelId}`,
    page: row.jumpUrl
    // envVersion: 'trial'
  }
  if (process.env.NODE_ENV == 'development') {
    data.envVersion = 'trial'
  } else {
    data.envVersion = 'release'
  }
  // 正式版为 "release"，体验版为 "trial"，开发版为 "develop"
  qrcodeApp(data).then(async res => {
    if (res.status == 200) {
      let text = await res.data.text()
      let jsonText = ''
      if (text.indexOf('5001') != -1) {
        jsonText = await JSON.parse(text)
        if (jsonText.code == 5001) {
          ElMessage.error(jsonText.msg)
        }
      } else {
        uploadImg(res.data)
      }
    }
  })
}

const uploadImg = file => {
  const time = new Date().getTime()
  putObject({ fileName: time + '.png' }).then(({ data: res }) => {
    if (res.code == 200) {
      client = new OSS({
        region: res.data.regionId,
        secure: true,
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        stsToken: res.data.securityToken,
        bucket: res.data.bucketName,
        cname: true,
        endpoint: `https://${res.data.cdnDomain}`
      })
      put(`${res.data.dirPath + res.data.fileName}`, file).then(
        ({ res: data }) => {
          if (data.status == 200) {
            wecharQrcode.value = data.requestUrls[0]
            remarkSubmit()
          }
        }
      )
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const put = async (ObjName, fileUrl) => {
  try {
    let result = await client.put(`${ObjName}`, fileUrl)
    // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称
    return result
  } catch (e) {
    console.log(e)
  }
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  labelVisible,
  title,
  from,
  imgDialogVisible,
  wecharQrcode,
  wecharQrcodeUrl
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.user_dialog {
  .writeoff_box {
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    span {
      width: 100px;
      text-align: right;

      i {
        color: red;
      }
    }

    .dialog_input {
      width: 50%;
    }
  }

  .remark_box {
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 117px;
  }

  .code_box {
    img {
      width: 120px;
      height: 120px;
    }
  }

  .block {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
}

.preImg {
  width: 300px;
  height: 300px;
  display: block;
  margin: 0 auto;
}
</style>
